import React, { useEffect, useState } from 'react';
import { Layout, Menu, theme } from 'antd';
import { useLocation } from 'react-router-dom';

export default function SubNav(props) {

    const { Sider } = Layout;
    const {token: { colorBgLayout, borderRadiusLG }} = theme.useToken();

    const state = useLocation();
    const [selectedSubNav, setSelectedSubNav] = useState('');

    useEffect(() => {
        setSelectedSubNav(state.pathname.split('/')[2]);
    });

    return (
        selectedSubNav !== '' && 
        <Sider
        key={state.pathname.split('/')[2] + state.pathname.split('/')[3] + state.pathname.split('/')[4]}
        width={200}
        
        style={{
            background: colorBgLayout,
            borderRadius: borderRadiusLG,
            padding: '0 0 24px'
        }}
        >
        <Menu
            mode="inline"
            defaultSelectedKeys={[state.pathname.split('/')[2], state.pathname.split('/')[3], state.pathname.split('/')[4]]}
            defaultOpenKeys={[state.pathname.split('/')[2], state.pathname.split('/')[3], state.pathname.split('/')[4]]}
            style={{
                height: '100%',
                borderRight: 0,
            }}
            items={props.subNavItems}
            />
        </Sider>
    );
}