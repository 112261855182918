import React, { useEffect } from 'react';
import { HddFilled, EnvironmentFilled, MessageFilled, SmileFilled, CalendarOutlined, TrophyOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';
import { Layout, Menu, Typography } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Paragraph from 'antd/es/typography/Paragraph';

const { Header, Footer } = Layout;
const { Text, Title } = Typography;

// Main
const mainMeNav = {key: 'me', icon: React.createElement(SmileFilled), label: <Link to="/me">Me</Link>};
const mainCompanyNav = {key: 'company', icon: React.createElement(HddFilled), label: <Link to="/company">Company</Link>};
const mainMapNav = {key: 'map', icon: React.createElement(EnvironmentFilled), label: <Link to="/map">Map</Link>};
const mainCommunityNav = {key: 'community', icon: React.createElement(MessageFilled), label: <Link to="/community">Community</Link>};
const mainNav = [mainMeNav, mainCompanyNav, mainMapNav, mainCommunityNav];

const HomePage = () => {

  return (
      <Layout style={{
        width: '1248px',
        margin: '0 auto',
        minHeight: '800px'
      }}>
        <Header
          style={{
            display: 'flex',
            padding: 0,
          }}
        >
          <div className="demo-logo" />
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[useLocation().pathname.split('/')[1]]}
            items={mainNav}
            style={{
              flex: 1,
              minWidth: 0,
            }}
          >
            <Text>Username</Text>
          </Menu>
          <div className="user-info">
            <Paragraph>
            <Text strong style={{color: 'white'}}>Username<br/>
              CompanyName<br/>
              Balance: <Text strong style={{color: 'lawngreen'}}>$2430</Text>
              </Text>
            </Paragraph>
            <br/>
          </div>
        </Header>
        <Outlet />
        <Footer style={{
          textAlign: 'center'
        }} >
          Footer
        </Footer>
      </Layout>
    );
  };

export default HomePage;