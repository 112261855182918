import React, { useEffect, useState } from 'react';
import { Col, Row, Divider, Table, Breadcrumb, Layout, theme, Modal, Input, Typography, InputNumber, Button } from 'antd';
import { DeleteOutlined  , DollarOutlined, PictureOutlined } from '@ant-design/icons';
import {WoodLogIcon, WoodPlankIcon } from '../../customIcons/iconComponents/CustomIcons.js';


const { Content } = Layout;
const { Text, Title } = Typography;

export default function Employees() {


    const [isSellItemModalOpen, setIsSellItemModalOpen] = useState(false);
    const [itemBeingSold, setItemBeingSold] = useState(null);
    const [dataSource, setDataSource] = useState([
        {
            key:'wood',
            title:'Wood',
            quantity:120,
            quality:1,
            value:50,
            decay:0.1,
            category:'Raw materials',
            icon: <WoodLogIcon />
        },
        {
            key:'plank',
            title:'Plank',
            quantity:20,
            quality:1,
            value:40,
            decay:0.1,
            category:'Intermediate',
            icon: <WoodPlankIcon />
        }
    ]);
    const columns = [
        {
            key:'1',
            title:'Item',
            render:(record)=>{
                return <>
                    {record.icon}
                    <Text> {record.title}</Text>
                </>
            }
        },
        {
            key:'2',
            title:'Quantity',
            dataIndex:'quantity'
        },
        {
            key:'3',
            title:'Quality',
            dataIndex:'quality'
        },
        {
            key:'4',
            title:'Value',
            dataIndex:'value'
        },
        {
            key:'5',
            title:'Decay',
            dataIndex:'decay'
        },
        {
            key:'6',
            title:'Category',
            dataIndex:'category'
        },
        {
            key:'7',
            title:'Actions',
            render:(record)=>{
                return <>
                <DollarOutlined  
                    onClick={()=>openSellItemModal(record)}/>
                </>
            }
        }
    ]

    const openSellItemModal = (record) => {
        setIsSellItemModalOpen(true);
        setItemBeingSold(record);
    }

    const closeSellItemModal = () => {
        setIsSellItemModalOpen(false);
        setItemBeingSold(null);
    }

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return(
        <Layout
            style={{
              padding: '0 24px 24px',
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
              <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
                <Title>Inventory</Title>
                <Table 
                columns={columns}
                dataSource={dataSource} >
                </Table>
                <Modal 
                    title={'Sell ' + itemBeingSold?.title + '?'}
                    open={isSellItemModalOpen}
                    onCancel={ () => {
                        closeSellItemModal();
                        }}
                    onOk={ () => {
                        setDataSource(pre=>{
                            return pre.map(job=>{
                                if(job.key === itemBeingSold.key){
                                    return itemBeingSold;
                                } else{
                                    return job;
                                }
                            })
                        });
                        closeSellItemModal();
                    }}
                    closable={false}
                    maskClosable={false}
                    >
                        <p>Quality: {itemBeingSold?.quality}</p>
                        <p>Value: {itemBeingSold?.value}</p>
                        <p>Quantity: {itemBeingSold?.quantity}</p>
                        <p>Sell: <InputNumber
                                    key={itemBeingSold?.key}
                                    value={itemBeingSold?.sellQuantity}
                                    style={{ width: '80px' }}
                                    min={0}
                                    max={itemBeingSold?.quantity}
                                    onChange={(e) => {
                                        setItemBeingSold(pre => {
                                            return {...pre, sellQuantity: e}
                                        });
                                    }}/>
                            (${typeof itemBeingSold?.sellQuantity !== 'undefined' ? itemBeingSold?.sellQuantity * itemBeingSold?.value : 0})
                        </p>
                        
                </Modal>
            </Content>
        </Layout>
    )
}