import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import {  ReadOutlined, NotificationOutlined, CommentOutlined, ProfileOutlined } from '@ant-design/icons';
import { Link, Outlet } from 'react-router-dom';
import SubNav from '../common/SubNav';

export default function Community() {

    const [subNavItems, setSubNavItems] = useState([]);

    useEffect (() => {
        // Community
        const communityChatNav = {key: 'chat', icon: React.createElement(CommentOutlined), label: <Link to="/community/chat">Chat</Link>};
        const communityWikiNav = {key: 'wiki', icon: React.createElement(ReadOutlined), label: <Link to="/community/wiki">Wiki</Link>};
        const communityRoadmapNav = {key: 'roadmap', icon: React.createElement(NotificationOutlined), label: <Link to="/community/roadmap">Roadmap</Link>};
        const communityPatchNotesNav = {key: 'patchnotes', icon: React.createElement(ProfileOutlined), label: <Link to="/community/patchnotes">Patch Notes</Link>};
        setSubNavItems([communityChatNav, communityWikiNav, communityRoadmapNav, communityPatchNotesNav]);
    }, [])

    return (
        <Layout >
          <SubNav subNavItems={subNavItems} />
          <Outlet />
        </Layout>
    );
};