import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import UnskilledWorkerSvg from '../svgs/unskilled-worker.svg';
import SkilledWorkerSvg from '../svgs/skilled-worker.svg';
import SpecialistSvg from '../svgs/specialist.svg';
import StationarySawSvg from '../svgs/stationary-saw.svg';
import WoodLogSvg from '../svgs/wood-log.svg';
import WoodPlankSvg from '../svgs/wood-plank.svg';
import BuildSvg from '../svgs/build.svg';
import ToolsSvg from '../svgs/tools.svg';
import WoodcutterSvg from '../svgs/woodcutter.svg';
import SawmillSvg from '../svgs/sawmill.svg';
import HousingSvg from '../svgs/housing.svg';
import { Popover } from 'antd';

const getUnskilledWorker = (size) => <img style={{width:size}} src={UnskilledWorkerSvg}></img>;
const getSkilledWorker = (size) => <img style={{width:size}} src={SkilledWorkerSvg}></img>;
const getSpecialist = (size) => <img style={{width:size}} src={SpecialistSvg}></img>;
const getStationarySaw = (size) => <img style={{width:size}} src={StationarySawSvg}></img>;
const getWoodLog = (size) => <img style={{width:size}} src={WoodLogSvg}></img>;
const getWoodPlank = (size) => <img style={{width:size}} src={WoodPlankSvg}></img>;
const getBuildSvg = (size) => <img style={{width:size}} src={BuildSvg}></img>;
const getToolsSvg = (size) => <img style={{width:size}} src={ToolsSvg}></img>;
const getWoodcutterSvg = (size) => <img style={{width:size}} src={WoodcutterSvg}></img>;
const getSawmillSvg = (size) => <img style={{width:size}} src={SawmillSvg}></img>;
const getHousingSvg = (size) => <img style={{width:size}} src={HousingSvg}></img>;

const createIcon = (title, size, popover, imgfunction) => {
    if (size === undefined) {size = '30px'}
    if (popover === undefined) {popover = true}
    if (popover) { return <Popover title={title}><Icon component = {() => imgfunction(size)} /></Popover> }
    else { return <Icon component = {() => imgfunction(size)} /> }
}

export function UnskilledWorkerIcon({size, popover}) {
    const popoverTitle = 'Unskilled worker';
    const imgfunction = getUnskilledWorker;
    return createIcon(popoverTitle, size, popover, imgfunction);
}

export function SkilledWorkerIcon({size, popover}) {
    const popoverTitle = 'Skilled worker';
    const imgfunction = getSkilledWorker;
    return createIcon(popoverTitle, size, popover, imgfunction);
}

export function SpecialistIcon({size, popover}) {
    const popoverTitle = 'Specialist';
    const imgfunction = getSpecialist;
    return createIcon(popoverTitle, size, popover, imgfunction);
}

export function StationarySawIcon({size, popover}) {
    const popoverTitle = 'Stationary saw';
    const imgfunction = getStationarySaw;
    return createIcon(popoverTitle, size, popover, imgfunction);
}

export function WoodLogIcon({size, popover}) {
    const popoverTitle = 'Log';
    const imgfunction = getWoodLog;
    return createIcon(popoverTitle, size, popover, imgfunction);
}

export function WoodPlankIcon({size, popover}) {
    const popoverTitle = 'Plank';
    const imgfunction = getWoodPlank;
    return createIcon(popoverTitle, size, popover, imgfunction);
}

export function BuildIcon({size, popover}) {
    const popoverTitle = 'Build';
    const imgfunction = getBuildSvg;
    return createIcon(popoverTitle, size, popover, imgfunction);
}

export function ToolsIcon({size, popover}) {
    const popoverTitle = 'Tools';
    const imgfunction = getToolsSvg;
    return createIcon(popoverTitle, size, popover, imgfunction);
}

export function WoodcutterIcon({size, popover}) {
    const popoverTitle = 'Woodcutter';
    const imgfunction = getWoodcutterSvg;
    return createIcon(popoverTitle, size, popover, imgfunction);
}

export function SawmillIcon({size, popover}) {
    const popoverTitle = 'Sawmill';
    const imgfunction = getSawmillSvg;
    return createIcon(popoverTitle, size, popover, imgfunction);
}

export function HousingIcon({size, popover}) {
    const popoverTitle = 'Housing';
    const imgfunction = getHousingSvg;
    return createIcon(popoverTitle, size, popover, imgfunction);
}