import React, { useEffect, useState } from 'react';
import { Col, Row, Flex, Table, Breadcrumb, Layout, theme, Space, Card, Typography, InputNumber, Popover } from 'antd';
import { DownOutlined, UpOutlined, DoubleRightOutlined, WarningFilled, PlusOutlined } from '@ant-design/icons';
import { UnskilledWorkerIcon, ToolsIcon, BuildIcon, StationarySawIcon, WoodLogIcon, WoodPlankIcon } from '../../customIcons/iconComponents/CustomIcons.js';


const { Content } = Layout;
const { Text, Title } = Typography;

export default function JobPriority() {

    const [employees, setEmployees] = useState(
        [
            {
                key:'unskilled',
                title:'Unskilled worker',
                quantity:5,
                availableForHire:50,
                wage:40,
                icon: <UnskilledWorkerIcon />
            }
        ]
    );
    const [inventory, setInventory] = useState(
        [
            {
                key:'wood',
                title:'Wood',
                quantity:120,
                quality:1,
                value:50,
                decay:0.1,
                category:'Raw materials',
                icon: <WoodLogIcon />
            },
            {
                key:'plank',
                title:'Plank',
                quantity:20,
                quality:1,
                value:40,
                decay:0.1,
                category:'Intermediate',
                icon: <WoodPlankIcon />
            }
        ]
    );
    const [jobs, setJobs] = useState([
        {
            key:1,
            jobName:'Cut logs',
            priority:1,
            duplicateTarget:2,
            employees:[
                {
                    key:'unskilled',
                    title:'Unskilled worker',
                    quantity:1,
                    icon: <UnskilledWorkerIcon/>
                }
            ],
            buildings:[],
            input:[],
            output:[
                {
                    key:'wood',
                    title:'Wood',
                    quantity:2,
                    icon:<WoodLogIcon />
                }
            ]
        },
        {
            key:2,
            jobName:'Saw planks',
            priority:2,
            duplicateTarget:1,
            employees:[
                {
                    key:'unskilled',
                    title:'Unskilled worker',
                    quantity:2,
                    icon: <UnskilledWorkerIcon/>
                },
            ],
            buildings:[
                {
                    key:'stationarySaw',
                    title:'Stationary saw',
                    quantity:1,
                    icon:<StationarySawIcon />
                }
            ],
            input:[
                {
                    key:'wood',
                    title:'Wood',
                    quantity:1,
                    icon:<WoodLogIcon />
                }
            ],
            output:[
                {
                    key:'plank',
                    title:'Plank',
                    quantity:4,
                    icon:<WoodPlankIcon />
                },
            ]
        },
        {
            key:3,
            jobName:'Build',
            priority:3,
            duplicateTarget:1,
            employees:[
                {
                    key:'unskilled',
                    title:'Unskilled worker',
                    quantity:1,
                    icon: <UnskilledWorkerIcon/>
                },
            ],
            buildings:[],
            input:[
                {
                    key:'tools',
                    title:'Tools',
                    quantity:0.1,
                    icon:<ToolsIcon />
                },
            ],
            output:[
                {
                    key:'build',
                    title:'Build',
                    quantity:1,
                    icon:<BuildIcon />
                },
            ]
        },
    ]);
    const [sites, setSites] = useState(
        [
            {
                id: 1,
                name: 'Woodcutter',
                buildings: [],
            },
            {
                id: 2,
                name: 'Sawmill',
                buildings: [
                    {
                        key:'stationarySaw',
                        title:'Stationary saw',
                        quantity:2,
                        icon:<StationarySawIcon />
                    }
                ],
            },
            {
                id: 3,
                name: 'Builder',
                buildings: [],
            },
        ]
    );
    const [buildings, setBuildings] = useState([]);
    const [productionInfo, setProductionInfo] = useState([]);
    
    const columns = [
        {
            key:'priority',
            title:'',
            width: '5%',
            colspan: 0,
            render:(record)=>{
                return <>
                    <UpOutlined style={{display: 'flex'}} onClick={()=>{upPriorityOfJob(record)}} />
                    <DownOutlined  style={{display: 'flex'}} onClick={()=>{downPriorityOfJob(record)}} />
                </>
            }
        },
        {
            key:'jobName',
            title:'Job name',
            dataIndex:'jobName',
        },
        {
            key:'operation',
            title:'Operation',
            align: 'center',
            width: '17%',
            render: (record) => <Space style={{width: '100%', flexFlow: 'wrap', justifyContent: 'center'}}>{record.employees.concat(record.buildings).map(operation => <div style={{paddingTop: '3px'}}><Text strong >{operation.quantity} </Text>{operation.icon}</div>)}</Space>
        },
        {
            key:'plus',
            title:'',
            align: 'center',
            width: '3%',
            render: () => <PlusOutlined />
        },
        {
            key:'input',
            title:'Input',
            align: 'center',
            width: '17%',
            render: (record) => <Space style={{width: '100%', flexFlow: 'wrap', justifyContent: 'center', backgroundColor: 'rgb(252 164 150)', borderRadius: '5px'}}>{record.input.map(input => <div style={{paddingTop: '3px'}}><Text strong >{input.quantity} </Text>{input.icon}</div>)}</Space>
        },
        {
            key:'arrow',
            title:'',
            align: 'center',
            width: '3%',
            render: () => <DoubleRightOutlined />
        },
        {
            key:'output',
            title:'Output',
            align: 'center',
            width: '17%',
            render: (record) => <Space style={{width: '100%', flexFlow: 'wrap', justifyContent: 'center', backgroundColor: 'rgb(197, 225, 165)', borderRadius: '5px'}}>{record.output.map(output => <div style={{paddingTop: '3px'}}><Text strong >{output.quantity} </Text>{output.icon}</div>)}</Space>
        },
        {
            key:'duplicateTarget',
            title:'Target',
            textAlign:'right',
            align: 'right',
            width: '11%',
            render:(record)=>{
                return <InputNumber 
                        key={record.key}
                        value={record.duplicateTarget}
                        style={{width: '60px'}}
                        min={0}
                        max={999}
                        onChange={(e) => editDuplicateTarget(e, record)}
                        />
            }
        },
        {
            key:'duplicateCapability',
            title:'',
            width: '7%',
            render:(record)=>{
                if(record.isNotEnoughAssetsForTarget) {
                    return <Popover title={'Not enough assets to complete target production'}>{'(' + record.duplicateCapability + ')'} <WarningFilled style={{color: '#faad14'}} /></Popover>}
                }
        },
        ]
        
        const [shouldCalculateDuplicateCapability, setShouldCalculateDuplicateCapability] = useState(true);
        const [shouldsortJobsByPriority, setShouldsortJobsByPriority] = useState(false);
        const [isNotEnoughAssetsForTarget, setIsNotEnoughAssetsForTarget] = useState(false);
        
        useEffect(()=>{
            setShouldCalculateDuplicateCapability(true);
            setShouldsortJobsByPriority(true);
    }, [])
    
    useEffect(()=>{
        if(shouldCalculateDuplicateCapability === true) {
            calculateDuplicateCapability();
        }
        if(shouldsortJobsByPriority) {
            sortJobsByPriority();
        }
    }, [jobs])

    useEffect(()=>{
        setBuildings(getFlattenedBuildingsList());
    }, [sites])

    const getFlattenedBuildingsList = () => {
        return sites.map(site=>{return site.buildings}).flat(1);
    }
    
    const editDuplicateTarget = (e, record) => {
        setShouldCalculateDuplicateCapability(true);
        setJobs(jobs.map((j)=>{
            if(j.key === record.key) {
                return { ...j,duplicateTarget: e };
            } else {
                return j;
            }
        }));
    }

    const calculateDuplicateCapability = () => {
        
        const jobsClone = jobs.map(job => ({ ...job }))
        const employeesClone = employees.map(employee => ({ ...employee }))
        const buildingClone = buildings.map(building => ({ ...building }))
        const inventoryClone = inventory.map(item => ({ ...item }))
        
        // For each job.
        jobsClone.forEach((job)=>{
            // Check how many duplicates we can complete
            job.duplicateCapability = job.duplicateTarget;
            job.employees.forEach((employeeRequired)=>{
                var employeeAvailable = employeesClone.find((employee)=>employeeRequired.key === employee.key && employeeRequired.quantity > 0);
                if(typeof employeeAvailable === 'undefined') {
                    employeeAvailable = {...employeeRequired, quantity:0};
                    employeesClone.push(employeeAvailable);
                }
                job.duplicateCapability = Math.min(job.duplicateCapability, Math.floor(employeeAvailable.quantity / employeeRequired.quantity));
            })
            job.buildings.forEach((buildingRequired)=>{
                var buildingAvailable = buildingClone.find((building)=>buildingRequired.key === building.key && buildingRequired.quantity > 0);
                if(typeof buildingAvailable === 'undefined') {
                    buildingAvailable = {...buildingRequired, quantity:0};
                    buildingClone.push(buildingAvailable);
                }
                job.duplicateCapability = Math.min(job.duplicateCapability, Math.floor(buildingAvailable.quantity / buildingRequired.quantity));
            })
            job.input.forEach((itemRequired)=>{
                var itemAvailable = inventoryClone.find((item)=>itemRequired.key === item.key && itemRequired.quantity > 0);
                if(typeof itemAvailable === 'undefined') {
                    itemAvailable = {...itemRequired, quantity:0};
                    inventoryClone.push(itemAvailable);
                }
                job.duplicateCapability = Math.min(job.duplicateCapability, Math.floor(itemAvailable.quantity / itemRequired.quantity));
            })
            // Set duplicateCapability and remove the requirements from cloned stockpile variables.
            job.isNotEnoughAssetsForTarget = job.duplicateTarget > job.duplicateCapability;

            job.employees.forEach((employeeRequired)=>{
                employeesClone.forEach((employeeAvailable)=>{
                    if(employeeRequired.key === employeeAvailable.key && employeeRequired.quantity > 0) {
                        employeeAvailable.missingQuantity = employeeAvailable.missingQuantity || 0-employeeAvailable.quantity;
                        employeeAvailable.quantity = employeeAvailable.quantity - (employeeRequired.quantity * job.duplicateCapability)
                        employeeRequired.targetQuantity = employeeRequired.quantity * job.duplicateTarget;
                        employeeRequired.actualQuantity = employeeRequired.quantity * job.duplicateCapability;
                        
                        employeeAvailable.missingQuantity = employeeAvailable.missingQuantity + employeeRequired.targetQuantity
                    }
                })
            })
            job.buildings.forEach((buildingRequired)=>{
                buildingClone.forEach((buildingAvailable)=>{
                    if(buildingRequired.key === buildingAvailable.key && buildingRequired.quantity > 0) {
                        buildingAvailable.missingQuantity = buildingAvailable.missingQuantity || 0-buildingAvailable.quantity;
                        buildingAvailable.quantity = buildingAvailable.quantity - (buildingRequired.quantity * job.duplicateCapability)
                        buildingRequired.targetQuantity = buildingRequired.quantity * job.duplicateTarget;
                        buildingRequired.actualQuantity = buildingRequired.quantity * job.duplicateCapability;

                        buildingAvailable.missingQuantity = buildingAvailable.missingQuantity + buildingRequired.targetQuantity
                    }
                })
            })
            job.input.forEach((itemRequired)=>{
                inventoryClone.forEach((itemAvailable)=>{
                    if(itemRequired.key === itemAvailable.key && itemRequired.quantity > 0) {
                        itemAvailable.missingQuantity = itemAvailable.missingQuantity || 0-itemAvailable.quantity;
                        itemAvailable.quantity = itemAvailable.quantity - (itemRequired.quantity * job.duplicateCapability)
                        itemRequired.targetQuantity = itemRequired.quantity * job.duplicateTarget;
                        itemRequired.actualQuantity = itemRequired.quantity * job.duplicateCapability;

                        itemAvailable.missingQuantity = itemAvailable.missingQuantity + itemRequired.targetQuantity
                    }
                })
            })
            job.output.forEach((itemCreated)=>{
                itemCreated.targetQuantity = itemCreated.quantity * job.duplicateTarget;
                itemCreated.actualQuantity = itemCreated.quantity * job.duplicateCapability;
            })
        });

        // Check if we have enough assets to complete target production
        setIsNotEnoughAssetsForTarget(jobsClone.some((job)=>{return job.duplicateTarget > job.duplicateCapability}));

        // Calculate production info (actual, target and available for employees, buildings, input and output)
        // Create a flatmap of all employees, buildings, input and output in active jobs.
        const productionFlatMap = jobs.map(job=>job.employees.map(employee=>{return {...employee, type:'employee'}}))
            .concat(jobs.map(job=>job.buildings.map(building=>{return {...building, type:'building'}})))
            .concat(jobs.map(job=>job.input.map(input=>{return {...input, type:'input'}})))
            .concat(jobs.map(job=>job.output.map(output=>{return {...output, type:'output'}})))
            .flatMap((arrays)=>arrays);
        // The flatmap above can contain multiple of the same item if multiple jobs include the same items.
        // Reduce the flatmap and summarize the values of each none-unique element.
        var productionInfoObject = productionFlatMap.reduce((acc, {key, type, title, actualQuantity, targetQuantity, icon})=>{
            if(targetQuantity > 0) {
                const reducedKey=type+key;
                acc[reducedKey] = acc[reducedKey] || { key, title, type, actualQuantity:0, targetQuantity:0, icon };
                acc[reducedKey].actualQuantity += actualQuantity;
                acc[reducedKey].targetQuantity += targetQuantity;
            }
            return acc;
        }, []);
        var productionInfoArray = [];
        for (let key in productionInfoObject) {
            productionInfoArray.push(productionInfoObject[key]);
        }

        const missingAssets = employeesClone.concat(buildingClone).concat(inventoryClone).filter((info)=>{if(info.missingQuantity > 0) {return info}});
        missingAssets.forEach((missingAsset) => productionInfoArray.forEach((productionInfo) => {if(productionInfo.key === missingAsset.key && productionInfo.type !== 'output') productionInfo.missingQuantity = missingAsset.missingQuantity}));

        // TODO this is where i left of :D (We seriously have to stop working on this page lol before i go crazy)
        
        setShouldCalculateDuplicateCapability(false);
        setProductionInfo(productionInfoArray);
        setJobs(jobsClone);
        console.log(productionInfoArray)
    }

    const upPriorityOfJob = ((record) => {
        const indexOfRecord = jobs.findIndex((job) => job === record);
        if(indexOfRecord !== 0) {
            const elementAbove = jobs[indexOfRecord-1];
            record.priority -= 1;
            elementAbove.priority +=1;
            sortJobsByPriority();
        }
    })

    const downPriorityOfJob = ((record) => {
        const indexOfRecord = jobs.findIndex((job) => job === record);
        if(indexOfRecord !== jobs.length-1) {
            const elementBelow = jobs[indexOfRecord+1];
            record.priority += 1;
            elementBelow.priority -=1;
            sortJobsByPriority();
        }
    })

    const sortJobsByPriority = (() => {
        setShouldsortJobsByPriority(false);
        setShouldCalculateDuplicateCapability(true);
        setJobs([...jobs.sort((a,b) => a.priority - b.priority)].slice());
    })
    
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    
    return(
        <Layout
        style={{
            padding: '0 24px 24px',
        }}
        >
            <Breadcrumb
              style={{
                  margin: '16px 0',
                }}
            >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
                <Title>Job Priority</Title>

                <div style={{height:'22px', marginBottom: '10px'}}> {isNotEnoughAssetsForTarget && <><WarningFilled style={{color: '#faad14'}} /> <Text strong>Not enough assets to complete target production</Text></>}</div>
                <Row>
                    <Col span={15}>
                        <Table 
                            size='medium'
                            columns={columns}
                            dataSource={ jobs } >
                        </Table>
                    </Col>
                    
                    <Col span={9}>
                        <Row>
                            <Col span={24} style={{padding: '0 8px 8px 8px'}}>
                                <Card title='Your Assets'
                                    style={{textAlign: 'center', backgroundColor: 'aliceblue'}}
                                    bodyStyle={{padding: '0px'}} >
                                    <div style={{padding: '5px', margin: '5px', minHeight: '44.25px'}}>
                                        <Space style={{flexFlow: 'wrap', justifyContent: 'center'}}>{employees.concat(buildings).map((info) => {return <Row><Space size={3}><Text strong>{info.quantity}</Text>{info.icon}</Space></Row>})}</Space>
                                    </div>
                                    <div style={{padding: '5px', margin: '5px', minHeight: '44.25px'}}>
                                        <Space style={{flexFlow: 'wrap', justifyContent: 'center'}}>{inventory.map((item) => {return <Row><Space size={3}><Text strong>{item.quantity}</Text>{item.icon}</Space></Row>})}</Space>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{padding: '8px'}}>
                                <Card title='Job Outcome'
                                    style={{textAlign: 'center', backgroundColor: 'linen'}}
                                    bodyStyle={{padding: '0px'}} >
                                    <div style={{padding: '5px', margin: '5px', minHeight: '44.25px'}}>
                                        <Space style={{flexFlow: 'wrap', justifyContent: 'center'}}>{productionInfo.map((info) => {if(info.type === 'employee' || info.type === 'building' && info.actualQuantity > 0) return <Row><Space size={3}><Text strong>{info.actualQuantity}</Text>{info.icon}</Space></Row>})}</Space>
                                    </div>
                                    <div style={{padding: '5px', margin: '5px', minHeight: '44.25px', backgroundColor: 'rgb(252 164 150)', borderRadius: '5px'}}>
                                        <Space style={{flexFlow: 'wrap', justifyContent: 'center'}}>{productionInfo.map((info) => {if(info.type === 'input' && info.actualQuantity > 0) return <Row><Space size={3}><Text strong >{info.actualQuantity}</Text>{info.icon}</Space></Row>})}</Space>
                                    </div>
                                    <div style={{padding: '5px', margin: '5px', minHeight: '44.25px', backgroundColor: '#c5e1a5', borderRadius: '5px'}}>
                                        <Space style={{flexFlow: 'wrap', justifyContent: 'center'}}>{productionInfo.map((info) => {if(info.type === 'output' && info.actualQuantity > 0) return <Row><Space size={3}><Text strong >{info.actualQuantity}</Text>{info.icon}</Space></Row>})}</Space>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        {isNotEnoughAssetsForTarget && <Row>
                            <Col span={24} style={{padding: '8px'}}>
                                <Card title='Missing Assets to reach Target'
                                    style={{textAlign: 'center', backgroundColor: 'whitesmoke'}}
                                    bodyStyle={{padding: '0px'}} >
                                    <div style={{padding: '5px', margin: '5px', minHeight: '44.25px'}}>
                                        <Space style={{flexFlow: 'wrap', justifyContent: 'center'}}>{productionInfo.map((info) => {if((info.type === 'employee' || info.type === 'building') && info.missingQuantity > 0) return <Row><Space size={3}><Text strong>{info.missingQuantity}</Text>{info.icon}</Space></Row>})}</Space>
                                    </div>
                                    <div style={{padding: '5px', margin: '5px', minHeight: '44.25px'}}>
                                        <Space style={{flexFlow: 'wrap', justifyContent: 'center'}}>{productionInfo.map((info) => {if(info.type === 'input' && info.missingQuantity > 0) return <Row><Space size={3}><Text strong >{info.missingQuantity}</Text>{info.icon}</Space></Row>})}</Space>
                                    </div>
                                </Card>
                            </Col>
                        </Row>}
                    </Col>
                </Row>
                
            </Content>
        </Layout>
    )
}