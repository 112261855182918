import React, { useState } from 'react';
import { Button, Table, Breadcrumb, Layout, theme, Modal, Input, Typography, InputNumber } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Text, Title } = Typography;

export default function Woodcutter() {

    
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    
    const [isEditingJob, setIsEditingJob] = useState(false);
    const [jobBeingEdited, setJobBeingEdited] = useState(null);
    const [unskilledAvailableCount, setUnskilledAvailableCount] = useState(3);
    const [skilledAvailableCount, setSkilledAvailableCount] = useState(0);
    const [specialistsAvailableCount, setSpecialistsAvailableCount] = useState(0);
    const [dataSource, setDataSource] = useState([
        {
            key:1,
            job:'Cut trees',
            unskilled:999,
            skilled:0,
            specialist:0
        },
        {
            key:2,
            job:'Cut them down!',
            unskilled:1,
            skilled:0,
            specialist:0
        },
        {
            key:3,
            job:'IMA FIRIN MAH LASAH.....',
            unskilled:0,
            skilled:0,
            specialist:0
        },
    ]);
    const columns = [
        {
            key:'1',
            title:'Job',
            dataIndex:'job'
        },
        {
            key:'2',
            title:'Unskilled ('+unskilledAvailableCount+')',
            render:(record)=>{
                return <>
                    <InputNumber
                        key={'unskilled'+record.key}
                        value={record.unskilled}
                        style={{
                            width: '60px'
                        }}
                        min={0}
                        max={999}
                        onChange={(e) => editFinalEmployeeCountOnJob(e, record, 'unskilled')}
                    ></InputNumber>
                </>
            }
        },
        {
            key:'3',
            title:'Skilled ('+skilledAvailableCount+')',
            render:(record)=>{
                return <>
                    <InputNumber 
                        key={'skilled'+record.key}
                        value={record.skilled}
                        style={{
                            width: '60px'
                        }}
                        min={0}
                        max={999}
                        onChange={(e) => editFinalEmployeeCountOnJob(e, record, 'skilled')}
                    ></InputNumber>
                </>
            }
        },
        {
            key:'4',
            title:'Specialist ('+specialistsAvailableCount+')',
            render:(record)=>{
                return <>
                    <InputNumber 
                        key={'specialist'+record.key}
                        value={record.specialist}
                        style={{
                            width: '60px'
                        }}
                        min={0}
                        max={999}
                        onChange={(e) => editFinalEmployeeCountOnJob(e, record, 'specialist')}
                    ></InputNumber>
                </>
            }
        },
        {
            key:'5',
            title:'Actions',
            render:(record)=>{
                return <>
                <EditOutlined 
                    onClick={()=>editJob(record)}/>
                <DeleteOutlined 
                    onClick={()=>onDeleteJob(record)} 
                    style={{ color: 'red', marginLeft: 12 }}/>
                </>
            }
        }
    ]
    
    const onAddJob = () => {
        const newJob = {
            key: parseInt(Math.random()*1000),
            job:'New job',
            unskilled:0,
            skilled:0,
            specialist:0
        };
        setDataSource(pre=>{
            return [...pre, newJob]
        });
    };
    
    const onDeleteJob = (record) => {
        Modal.confirm({
            title:'Delete '+record.job+'?',
            onOk: () => {
                setDataSource(pre=>{
                    return pre.filter(job => job.key !== record.key);
                });
            }
        });
    };

    const editJob = (record) => {
        setIsEditingJob(true);
        setJobBeingEdited(record);
    }

    const closeEditingJobModal = () => {
        setIsEditingJob(false);
        setJobBeingEdited(null);
    }

    const editFinalEmployeeCountOnJob = (newEmployeeCount, record, employeeType) => {
        const oldEmployeeCount = record[employeeType];
        const diff = newEmployeeCount - oldEmployeeCount;
        const availableEmployeesCount = employeeType === 'unskilled' ? unskilledAvailableCount : 
            employeeType === 'skilled' ? skilledAvailableCount : specialistsAvailableCount;
        const newEmployeesAvailableCount = availableEmployeesCount - diff;
        if(newEmployeesAvailableCount < 0) {
            // Not enough available employees
            console.log('Not enough available employees: ' + employeeType)
        } else {
            if(employeeType === 'unskilled') setUnskilledAvailableCount(newEmployeesAvailableCount);
            if(employeeType === 'skilled') setSkilledAvailableCount(newEmployeesAvailableCount);
            if(employeeType === 'specialist') setSpecialistsAvailableCount(newEmployeesAvailableCount);
            record[employeeType] = newEmployeeCount;
        }
    }

    return (
        <Layout
            style={{
              padding: '0 24px 24px',
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
              <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
            <Title>Woodcutter</Title>
            <Table 
                style={{
                    width: '45%',
                }}
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.job}</p>,
                    rowExpandable: (record) => record.job !== 'Cutt trees',
                  }}
                dataSource={dataSource} >
            </Table>
            <div style={{
                position: 'relative',
                bottom: '50px'
              }}>
                <Button onClick={onAddJob}> <PlusOutlined />New Job</Button>
            </div>
            <Modal 
            title="Job details"
            open={isEditingJob}
            onCancel={ () => {
                closeEditingJobModal();
                }}
            onOk={ () => {
                setDataSource(pre=>{
                    return pre.map(job=>{
                        if(job.key === jobBeingEdited.key){
                            return jobBeingEdited;
                        } else{
                            return job;
                        }
                    })
                });
                closeEditingJobModal();
            }}
            closable={false}
            maskClosable={false}
            >
                <Text>Job name</Text>
                <Input value={jobBeingEdited?.job} onChange={(e) => {
                    setJobBeingEdited(pre => {
                        return {...pre, job: e.target.value}
                    });
                }}/>
            </Modal>
            </Content>
          </Layout>
    );

}