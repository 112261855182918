import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { CalendarOutlined, TrophyOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';
import { Link, Outlet } from 'react-router-dom';
import SubNav from '../common/SubNav';

export default function Me(props) {

    const [subNavItems, setSubNavItems] = useState([]);

    useEffect (() => {
        // Me
        const meProfileNav = {key: 'profile', icon: React.createElement(UserOutlined), label: <Link to="/me/profile">Profile</Link>};
        const meScheduleNav = {key: 'schedule', icon: React.createElement(CalendarOutlined), label: <Link to="/me/schedule">Schedule</Link>};
        const meAchievementsNav = {key: 'achievements', icon: React.createElement(TrophyOutlined ), label: <Link to="/me/achievements">Achievements</Link>};
        const meSettingsNav = {key: 'settings', icon: React.createElement(SettingOutlined), label: <Link to="/me/settings">Settings</Link>};
        setSubNavItems([meProfileNav, meScheduleNav, meAchievementsNav, meSettingsNav]);
    }, [])

    return (
        <Layout >
          <SubNav subNavItems={subNavItems} />
          <Outlet />
        </Layout>
    );
};