import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Link, Outlet } from 'react-router-dom';
import SubNav from '../common/SubNav';

export default function Map() {

    const [subNavItems, setSubNavItems] = useState([]);

    useEffect (() => {
        // Map
        const mapColorCodeNav = {key: 'colorcoding', icon: React.createElement(SearchOutlined), label: 'Color Coding',
            children: [
                {key: 'population', label: <Link to="/map/colorcoding/population">Population</Link>},
                {key: 'demand', label: <Link to="/map/colorcoding/demand">Demand</Link>},
                {key: 'resource', label: <Link to="/map/colorcoding/resource">Resource</Link>},
                {key: 'fertility', label: <Link to="/map/colorcoding/fertility">Fertility</Link>},
                {key: 'availableland', label: <Link to="/map/colorcoding/availableland">Available Land</Link>}
            ]
        };
        setSubNavItems([mapColorCodeNav]);
    }, [])

    return (
        <Layout >
          <SubNav subNavItems={subNavItems} />
          <Outlet />
        </Layout>
    );
};