import React from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';
import HomePage from './components/common/HomePage.js';
import Me from './components/me/Me.js';
import Company from './components/company/Company.js';
import Map from './components/map/Map.js';
import Community from './components/community/Community.js';
import MyContent from './components/common/MyContent.js';
import NotFoundPage from './components/common/NotFoundPage.js';
import Woodcutter from './components/company/Woodcutter.js';
import Employees from './components/company/Employees.js';
import Inventory from './components/company/Inventory.js';
import JobPriority from './components/company/JobPriority.js';
import Construction from './components/company/Construction.js';
import { ConfigProvider, theme } from 'antd';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    errorElement: <NotFoundPage />,
    children: [
      {
        index: true,
        loader: async () => redirect('/me'),
      },
      {
        path: '/me',
        element: <Me />,
        children: [
          {
            index: true,
            loader: async () => redirect('/me/schedule'),
          },
          {
            path: '/me/profile',
            element: <MyContent contentText="Profile not yet implemented." />
          },
          {
            path: '/me/schedule',
            element: <MyContent contentText="Schedule not yet implemented." />
          },
          {
            path: '/me/achievements',
            element: <MyContent contentText="Achievements not yet implemented." />
          },
          {
            path: '/me/settings',
            element: <MyContent contentText="Settings not yet implemented." />
          }
        ]
      },
      {
        path: '/company',
        element: <Company />,
        children: [
          {
            index: true,
            loader: async () => redirect('/company/analytics'),
          },
          {
            path: '/company/analytics',
            children: [
              {
                index: true,
                loader: async () => redirect('/company/analytics/dailyreport'),
              },
              {
                path: '/company/analytics/dailyreport',
                element: <MyContent contentText="Daily report not yet implemented." />
              },
              {
                path: '/company/analytics/economy',
                element: <MyContent contentText="Economy not yet implemented." />
              }
            ]
          },
          {
            path: '/company/realestate',
            children: [
              {
                index: true,
                loader: async () => redirect('/company/realestate/employees'),
              },
              {
                path: '/company/realestate/jobpriority',
                element: <JobPriority />
              },
              {
                path: '/company/realestate/sites',
                children: [
                  {
                    index: true,
                    loader: async () => redirect('/company/realestate/sites/woodcutter'),
                  },
                  {
                    path: '/company/realestate/sites/woodcutter',
                    element: <Woodcutter />
                  },
                  {
                    path: '/company/realestate/sites/sawmill',
                    element: <MyContent contentText="Sites not yet implemented." />
                  }
                ]
              },
              {
                path: '/company/realestate/inventory',
                element: <Inventory />
              },
              {
                path: '/company/realestate/employees',
                element: <Employees />
              },
              {
                path: '/company/realestate/construction',
                element: <Construction />
              }
            ]
          },
          {
            path: '/company/trade',
            element: <MyContent contentText="Trade not yet implemented." />
          }
        ]
      },
      {
        path: '/map',
        element: <Map />,
        children: [
          {
            index: true,
            loader: async () => redirect('/map/colorcoding/population'),
          },
          {
            path: '/map/colorcoding',
            children: [
              {
                index: true,
                loader: async () => redirect('/map/colorcoding/population'),
              },
              {
                path: '/map/colorcoding/population',
                element: <MyContent contentText="Map (population view) not yet implemented." />
              },
              {
                path: '/map/colorcoding/demand',
                element: <MyContent contentText="Map (demand view) not yet implemented." />
              },
              {
                path: '/map/colorcoding/resource',
                element: <MyContent contentText="Map (resource view) not yet implemented." />
              },
              {
                path: '/map/colorcoding/fertility',
                element: <MyContent contentText="Map (fertility view) not yet implemented." />
              },
              {
                path: '/map/colorcoding/availableland',
                element: <MyContent contentText="Map (available land view) not yet implemented." />
              }
            ]
          }
        ]
      },
      {
        path: '/community',
        element: <Community />,
        children: [
          {
            index: true,
            loader: async () => redirect('/community/chat'),
          },
          {
            path: '/community/chat',
            element: <MyContent contentText="Chat not yet implemented." />
          },
          {
            path: '/community/wiki',
            element: <MyContent contentText="Wiki not yet implemented." />
          },
          {
            path: '/community/roadmap',
            element: <MyContent contentText="Roadmap not yet implemented." />
          },
          {
            path: '/community/patchnotes',
            element: <MyContent contentText="Patch notes not yet implemented." />
          }
        ]
      }
    ]
  }
]);

const App = () => {
  
  return (
    <ConfigProvider
      theme={{
        // It's possible to combine dark algorithm and compact algorithm
        // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        algorithm: theme.defaultAlgorithm,
      }}
    >
    <div style={{
      backgroundColor: '#f5f5f5',
      height: '100vh'
    }} >
      <RouterProvider router={router} />
    </div>
    
  </ConfigProvider>
  );
};
export default App;