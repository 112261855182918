import React, { useEffect, useState } from 'react';
import { Col, Row, Divider, Card, Breadcrumb, Layout, theme, Modal, Input, Typography, InputNumber, Button } from 'antd';
import { UnskilledWorkerIcon, SkilledWorkerIcon, SpecialistIcon } from '../../customIcons/iconComponents/CustomIcons.js';

const { Content } = Layout;
const { Text, Title } = Typography;

export default function Employees() {

    const [dataSource, setDataSource] = useState([
        {
            key:'unskilled',
            title:'Unskilled worker',
            quantity:5,
            availableForHire:50,
            wage:40,
            icon: <UnskilledWorkerIcon popover={false} />
        },
        {
            key:'skilled',
            title:'Skilled worker',
            quantity:0,
            availableForHire:1,
            wage:90,
            icon: <SkilledWorkerIcon popover={false} />
        },
        {
            key:'specialist',
            title:'Specialist',
            quantity:0,
            availableForHire:0,
            wage:140,
            icon: <SpecialistIcon popover={false} />
        },
    ]);

    const editEmployeesQuantity = (newEmployeeCount, data) => {
        const oldEmployeeCount = data.quantity;
        const diff = newEmployeeCount - oldEmployeeCount;
        const newEmployeesAvailableCount = data.availableForHire - diff;
        if(newEmployeesAvailableCount < 0) {
            // Not enough available employees
            console.log('Not enough available employees: ' + data.title)
        } else {
            setDataSource(dataSource.map((d)=>{
                if(d.key === data.key) {
                    return {...d,
                        tmpQuantity: newEmployeeCount
                    };
                } else {
                    return d;
                }
            }));

        }
    }

    const saveEmployeesQuantity = (data) => {
        // save tmp to perm
        // post
        // update available for hire
    }

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return(
        <Layout
            style={{
              padding: '0 24px 24px',
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
              <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
                <Title>Employees</Title>
                <Text>Worker wage and availability vary depending on tile.</Text>
                <Row gutter={16}>
                    {dataSource.map(d => (
                    <Col span={8}>
                        <Card hoverable={true} 
                        title={<>{d.icon} {d.title}</>} >
                            <p>Wage: {d.wage}</p>
                            <p>Available for hire: {d.availableForHire}</p>
                            <Divider />
                            <p>Hired: 
                                <InputNumber
                                    key={d.key}
                                    value={typeof d.tmpQuantity !== 'undefined' ? d.tmpQuantity : d.quantity}
                                    style={{
                                        width: '60px'
                                    }}
                                    min={0}
                                    max={999}
                                    onChange={(e) => editEmployeesQuantity(e, d)}
                                />
                            <Button type="primary" danger={d.tmpQuantity - d.quantity < 0} disabled={typeof d.tmpQuantity === 'undefined' || d.quantity - d.tmpQuantity === 0} >{d.tmpQuantity - d.quantity < 0 ? 'Fire ' + (d.quantity - d.tmpQuantity) + ' employees' : 'Hire ' + (!d.tmpQuantity ? 0 : d.tmpQuantity - d.quantity) + ' employees'}</Button>
                            </p>
                        </Card>
                    </Col>
                    ))}
                </Row>
            </Content>
          </Layout>
    )
}