import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { TruckOutlined, HomeOutlined, LineChartOutlined } from '@ant-design/icons';
import { Link, Outlet } from 'react-router-dom';
import SubNav from '../common/SubNav';

export default function Company() {

    const [subNavItems, setSubNavItems] = useState([]);

    useEffect (() => {
        // Company
        const companyAnalyticsNav = {key: 'analytics', icon: React.createElement(LineChartOutlined), label: 'Analytics',
            children: [
                {key: 'dailyreport', label: <Link to="/company/analytics/dailyreport">Daily Report</Link>},
                {key: 'economy', label: <Link to="/company/analytics/economy">Economy</Link>}
            ]};
        const companyRealEstateNav = {key: 'realestate', icon: React.createElement(HomeOutlined), label: 'Real Estate',
            children: [
                {key: 'jobpriority', label: <Link to="/company/realestate/jobpriority">Job Priority</Link>},
                {key: 'sites', label: 'Sites',
                children: [
                    {key: 'woodcutter', label: <Link to="/company/realestate/sites/woodcutter">Woodcutter</Link>},
                    {key: 'sawmill', label: <Link to="/company/realestate/sites/sawmill">Sawmill</Link>}
                ]},
                {key: 'inventory', label: <Link to="/company/realestate/inventory">Inventory</Link>},
                {key: 'employees', label: <Link to="/company/realestate/employees">Employees</Link>},
                {key: 'construction', label: <Link to="/company/realestate/construction">Construction</Link>}
            ]
        };
        const companyTradeNav = {key: 'trade', icon: React.createElement(TruckOutlined), label: <Link to="/company/trade">Trade</Link>};
        setSubNavItems([companyAnalyticsNav, companyRealEstateNav, companyTradeNav]);
    }, [])

    return (
        <Layout >
          <SubNav subNavItems={subNavItems} />
          <Outlet />
        </Layout>
    );
};