import React, { useEffect, useState } from 'react';
import { Col, Row, Modal, Table, Breadcrumb, Layout, theme, Button, Divider, Typography, Space, InputNumber, Dropdown } from 'antd';
import { EditOutlined, DeleteOutlined, DownOutlined, CheckOutlined , PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { UnskilledWorkerIcon, HousingIcon, BuildIcon, WoodcutterIcon, SawmillIcon, WoodPlankIcon } from '../../customIcons/iconComponents/CustomIcons.js';


const { Content } = Layout;
const { Text, Title } = Typography;

export default function Construction() {
    
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [isEditingProject, setIsEditingProject] = useState(false);
    const [projectBeingEdited, setProjectBeingEdited] = useState(null);
    const [buildingTypes, setBuildingTypes] = useState([
        {
            key: 'woodcutter',
            title: 'Woodcutter',
            icon: <WoodcutterIcon />,
            facilities: [
                {
                    key: 'basicworkplace',
                    title: 'Basic workplace',
                    required: true,
                    description: 'A basic workplace has the absolute minimum requirement to facilitate your employees',
                    components: [],
                    size: 10,
                },
            ]
        },
        {
            key: 'housing',
            title: 'Housing',
            icon: <HousingIcon />,
            facilities: [
                {
                    key: 'basiclivingarea',
                    title: 'Basic living area',
                    required: true,
                    description: 'A basic living area provides shelter and is the minimum requirement for a home',
                    size: 10,
                    components: [],
                },
                {
                    key: 'bedroom',
                    title: 'Bedroom',
                    required: false,
                    description: 'A place for rest and relaxation',
                    size: 10,
                    components: [
                        {
                            key: 'bed',
                            title: 'Bed',
                            required: true,
                            description: 'zzzzZZZZZ',
                            qualityFlatBonus: 1,
                            qualityScaling: 0.5,
                            size: 5
                        },
                        {
                            key: 'closet',
                            title: 'Closet',
                            required: false,
                            description: 'Where do you think all those clothes end up?',
                            qualityFlatBonus: 1,
                            qualityScaling: 0.5,
                            size: 5
                        }
                    ],
                },
            ]
        }
    ]);
    const [projects, setProjects] = useState([
        {
            key:1,
            buildingTypeKey:'woodcutter',
            buildingType:{},
            facilities: [
                {
                    key: 'basicworkplace',
                    title: 'Basic workplace',
                    required: true,
                    description: 'A basic workplace has the absolute minimum requirement to facilitate your employees',
                    components: [],
                    size: 10,
                },
            ],
            remainingWork: [
                {
                    key:'build',
                    title:'Build',
                    quantity:10,
                    icon:<BuildIcon />
                }
            ],
        },
        {
            key:2,
            buildingTypeKey:'housing',
            buildingType:{},
            facilities: [
                {
                    key: 'basiclivingarea',
                    title: 'Basic living area',
                    required: true,
                    description: 'A basic living area provides shelter and is the absolute minimum requirement for a home',
                    size: 10,
                    components: [],
                },
            ],
            remainingWork: [
                {
                    key:'build',
                    title:'Build',
                    quantity:20,
                    icon:<BuildIcon />
                }
            ],
        },
        {
            key:3,
            buildingTypeKey:'housing',
            buildingType:{},
            facilities: [
                {
                    key: 'basiclivingarea',
                    title: 'Basic living area',
                    required: true,
                    description: 'A basic living area provides shelter and is the absolute minimum requirement for a home',
                    size: 10,
                    components: [],
                },
            ],
            remainingWork: [
                {
                    key:'build',
                    title:'Build',
                    quantity:20,
                    icon:<BuildIcon />
                }
            ],
        },
    ]);
    const columns = [
        {
            key:'1',
            title:'Project',
            render:(record)=> <Space>{record.buildingType.icon}{record.buildingType.title}</Space>//record.project
        },
        {
            key:'2',
            title:'Remaining work',
            render:(record)=>{
                return record.remainingWork?.map((work)=><Space><Text strong >{work.quantity}</Text>{work.icon}</Space>)
            }
        },
        {
            key:'3',
            title:'',
            render:(record)=>{
                return <>
                <EditOutlined 
                    onClick={()=>openEditingProjectModal(record)}/>
                <DeleteOutlined 
                    onClick={()=>deleteProject(record)} 
                    style={{ color: 'red', marginLeft: 12 }}/>
                </>
            }
        }
    ]

    useEffect(()=>{
        setProjects(projects.map((project)=>project={...project, buildingType: getBuildingType(project.buildingTypeKey)}))
    }, []);

    useEffect(()=>{
        console.log(projectBeingEdited?.facilities)
    }, [projectBeingEdited]);

    const newProject = () => {
        const newProject = {
            key: parseInt(Math.random()*1000),
            buildingTypeKey: buildingTypes[0].key,
            buildingType: getBuildingType(buildingTypes[0].key),
            facilities: getRequiredFacilities(buildingTypes[0].key),
            remainingWork: [],
        };
        setProjects(pre=>{
            return [...pre, newProject]
        });
    };
    
    const deleteProject = (record) => {
        Modal.confirm({
            title:'Delete '+record.buildingType.title+'?',
            onOk: () => {
                setProjects(pre=>{
                    return pre.filter(project => project.key !== record.key);
                });
            }
        });
    };

    const openEditingProjectModal = (record) => {
        setIsEditingProject(true);
        setProjectBeingEdited(record);
    }

    const closeEditingProjectModal = () => {
        setIsEditingProject(false);
        setProjectBeingEdited(null);
    }

    const getBuildingType = (buildingTypeKey) => {
        return buildingTypes.find((type)=>buildingTypeKey === type.key);
    } 

    const getRequiredFacilities = (buildingTypeKey) => {
        const buildingType = getBuildingType(buildingTypeKey);
        return buildingType.facilities.filter((facility)=>facility.required);
    }

    const getOptionalFacilities = (buildingTypeKey) => {
        const buildingType = getBuildingType(buildingTypeKey);
        return buildingType?.facilities.filter((facility)=>!facility.required);
    }

    const getOptionalComponents = (facilityKey) => {
        const facilityFlatMap = buildingTypes.map((type)=>type.facilities).flat();
        const facility = facilityFlatMap.find((fac=>fac.key === facilityKey));
        return facility?.components.filter((component)=>!component.required);
    }

    const getFacilityByKey = (facilityKey) => {
        const facilityFlatMap = buildingTypes.map((type)=>type.facilities).flat();
        return facilityFlatMap.find((facility)=>facility.key === facilityKey);
    }

    const buildingMaterialMenuProps = {
        items: [{label: 'Wood', key: 'wood', icon: <></>}],
        onClick: (e)=>{
            
        },
    };

    const createAddFacilityMenuProps = () => {
        const addFacilityMenuProps = {
            items: getOptionalFacilities(projectBeingEdited.buildingType.key).map((facility)=>{return {label: facility.title, key: facility.key, icon: <></>}}),
            onClick: (e)=>{
                setProjectBeingEdited({
                    ...projectBeingEdited,
                    facilities: [...projectBeingEdited.facilities, {...getFacilityByKey(e.key), components: []}]
                });
            },
        };
        return addFacilityMenuProps;
    };

    const createAddComponentMenuProps = (facilityKey) => {
        console.log('im here so often');
        const addComponentMenuProps = {
            items: getOptionalComponents(facilityKey).map((component)=>{return {label: component.title, key: component.key, icon: <></>}}),
            onClick: (e)=>{
                console.log('i only hit this once');
                const fac = getFacilityByKey(facilityKey);
                const myNewComponent = fac.components.find((f)=>f.key === e.key);
                const projectClone = {...projectBeingEdited};
                //const projectClone = structuredClone(projectBeingEdited);
                const indexOfTheFacilityImEditing = projectClone.facilities.findIndex((fac)=>fac.key===facilityKey);
                const theFacilityImEditing = projectClone.facilities[indexOfTheFacilityImEditing];
                projectClone.facilities.splice(indexOfTheFacilityImEditing, 1, {...theFacilityImEditing, components: [...theFacilityImEditing.components, myNewComponent]});
                console.log(projectClone);
                setProjectBeingEdited(projectClone);
            },
        };
            return addComponentMenuProps;
    };

    const buildingTypeMenuProps = {
        items: buildingTypes.map((type)=>{return {label: type.title, key: type.key, icon: <></>}}),
        onClick: (e)=>{
            if(e.key !== projectBeingEdited.buildingTypeKey) {
                setProjectBeingEdited({
                    ...projectBeingEdited,
                    buildingTypeKey: e.key,
                    buildingType: getBuildingType(e.key),
                    facilities: getRequiredFacilities(e.key),
                    remainingWork: []
                })
            }
        },
    };
    
    return(
        <Layout
            style={{
            padding: '0 24px 24px',
            }}
            >
            <Breadcrumb
            style={{
                margin: '16px 0',
            }}
            >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb>
            <Content
            style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
            }}
            >
                <Title>Construction</Title>
                <Table 
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.buildingType.title}</p>,
                        rowExpandable: (record) => record.project !== 'Cutt trees',
                    }}
                    dataSource={projects} >
                </Table>
                <div style={{
                    position: 'relative',
                    bottom: '50px'
                }}>
                    <Button onClick={newProject}> <PlusOutlined />New Project</Button>
                </div>
                <Modal 
                    title="Project details"
                    open={isEditingProject}
                    onCancel={ () => {
                        closeEditingProjectModal();
                        }}
                    onOk={ () => {
                        setProjects(pre=>{
                            return pre.map(project=>{
                                if(project.key === projectBeingEdited.key){
                                    return projectBeingEdited;
                                } else{
                                    return project;
                                }
                            })
                        });
                        closeEditingProjectModal();
                    }}
                    closable={false}
                    maskClosable={false}
                    >
                        <div>
                            <Space>
                                <Text>Building material:</Text>
                                <Dropdown menu={buildingMaterialMenuProps}>
                                    <Button>
                                        <Space>
                                            Wood
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                                <Text>Building size:</Text>
                                <InputNumber
                                    key={projectBeingEdited?.key}
                                    value={projectBeingEdited?.size}
                                    style={{
                                        width: '60px'
                                    }}
                                    min={0}
                                    max={999}
                                    onChange={(e) => {}}
                                    />
                                <Text>sqm</Text>
                            </Space>
                        </div>
                        <div>
                            <Text>Building type: </Text>
                            <Dropdown menu={buildingTypeMenuProps}>
                                <Button>
                                    <Space>
                                        {projectBeingEdited?.buildingType.title}
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </div>
                        {typeof projectBeingEdited?.buildingType !== 'undefined' && 
                        <>
                            <div><Text>Facilities: </Text></div>
                            <div style={{marginLeft: '20px'}}>
                                {projectBeingEdited.facilities?.map((facility)=>{
                                    return <>
                                    <Space>
                                        <Text>{facility.title}</Text>
                                        <Text>Required: {facility.required ? <CheckOutlined style={{color:'green'}}/> : <CloseOutlined style={{color:'red'}}/>}</Text>
                                        <Text>Size: {facility.size} sqm</Text>
                                        <Text>Quantity: {facility.quantity}</Text>
                                    </Space>
                                    <div>
                                        {facility.components.length > 0 && <Text>Components: </Text>}
                                        <div style={{marginLeft: '20px'}}>
                                        {facility.components.map((component)=>{
                                            return <Space>
                                                <Text>{component.title}</Text>
                                                <Text>Required: {component.required ? <CheckOutlined style={{color:'green'}}/> : <CloseOutlined style={{color:'red'}}/>}</Text>
                                                <Text>Size: {component.size} sqm</Text>
                                                <Text>Quantity: {component.quantity}</Text>
                                            </Space>
                                        })}
                                        <div>
                                        <Dropdown menu={createAddComponentMenuProps(facility.key)}>
                                            <Button>
                                                <Space>
                                                    Add Component
                                                    <DownOutlined />
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                        </div>
                                        </div>
                                    </div>
                                    <Divider />
                                    </>
                                })}
                                <Dropdown menu={createAddFacilityMenuProps()}>
                                <Button>
                                    <Space>
                                        Add Facility
                                        <DownOutlined />
                                    </Space>
                                </Button>
                                </Dropdown>
                            </div>
                        </>
                        }
                </Modal>
            </Content>
        </Layout>
    )
}